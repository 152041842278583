<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, type TextareaHTMLAttributes, type InputHTMLAttributes } from 'vue'

import { useMainStore } from '../stores/main'

import type { IconLabel } from '../types'

export type InputType = 'textarea' | 'text' | 'email' | 'tel' | 'url' | 'number' | 'search' | 'password'

export type InputMode<Type extends InputType | undefined = 'text'> = Type extends 'text'
  ? 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
  : 'text'

export type ValueType<Type extends InputType | undefined = 'text'> = Type extends 'textarea'
  ? TextareaHTMLAttributes['value']
  : InputHTMLAttributes['value']

export type OptionsType = Array<string | { id: string; label: string }> | undefined

interface FormControlProps {
  name?: string
  id?: string
  autocomplete?: string
  maxlength?: string
  placeholder?: string
  inputmode?: InputMode<FormControlProps['type']>
  icon?: IconLabel
  options?: OptionsType
  type?: InputType
  modelValue?: ValueType<FormControlProps['type']>
  required?: boolean
  borderless?: boolean
  transparent?: boolean
  ctrlKFocus?: boolean
}

const props = withDefaults(defineProps<FormControlProps>(), {
  name: undefined,
  id: undefined,
  autocomplete: undefined,
  maxlength: undefined,
  placeholder: undefined,
  inputmode: undefined,
  icon: undefined,
  options: undefined,
  type: 'text',
  modelValue: '',
  required: false,
  borderless: false,
  transparent: false,
  ctrlKFocus: false,
})

const emit = defineEmits<{
  (_e: 'update:modelValue', _value: FormControlProps['modelValue']): void
  (_e: 'setRef', _el: HTMLElement | null): void
}>()

const computedValue = computed({
  get: () => props.modelValue,
  set: (value: FormControlProps['modelValue']) => {
    emit('update:modelValue', value)
  },
})

const inputElClass = computed(() => {
  const base = [
    tw`px-3 py-2 max-w-full focus:ring focus:outline-none border-jade-blue rounded w-full`,
    tw`dark:placeholder-pumice`,
    computedType.value === 'textarea' ? 'h-24' : 'h-12',
    props.borderless ? 'border-0' : 'border',
    props.transparent ? 'bg-transparent' : tw`bg-pitaya-core dark:bg-pitaya-pip-700`,
  ]

  if (props.icon) {
    base.push('pl-10')
  }

  return base
})

const computedType = computed(() => (props.options ? 'select' : props.type))

const controlIconH = computed(() => (props.type === 'textarea' ? 'h-full' : 'h-12'))

const mainStore = useMainStore()

const selectEl = ref<HTMLSelectElement | null>(null)
const textareaEl = ref<HTMLTextAreaElement | null>(null)
const inputEl = ref<HTMLInputElement | null>(null)

onMounted(() => {
  if (selectEl.value) {
    emit('setRef', selectEl.value)
  } else if (textareaEl.value) {
    emit('setRef', textareaEl.value)
  } else if (inputEl.value) {
    emit('setRef', inputEl.value)
  }
})

if (props.ctrlKFocus) {
  const fieldFocusHook = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault()
      inputEl.value?.focus()
    } else if (e.key === 'Escape') {
      inputEl.value?.blur()
    }
  }

  onMounted(() => {
    if (!mainStore.isFieldFocusRegistered) {
      window.addEventListener('keydown', fieldFocusHook)
      mainStore.isFieldFocusRegistered = true
    } else {
      // console.error('Duplicate field focus event')
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', fieldFocusHook)
    mainStore.isFieldFocusRegistered = false
  })
}
</script>

<template>
  <div class="relative">
    <select v-if="computedType === 'select'" :id="id" v-model="computedValue" :name="name" :class="inputElClass">
      <option v-for="option in options" :key="typeof option === 'object' ? option.id : option" :value="option">
        {{ typeof option === 'object' ? option.label : option }}
      </option>
    </select>
    <textarea
      v-else-if="computedType === 'textarea'"
      :id="id"
      v-model="computedValue"
      :class="inputElClass"
      :name="name"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :required="required"
    />
    <input
      v-else
      :id="id"
      ref="inputEl"
      v-model="computedValue"
      :name="name"
      :maxlength="maxlength"
      :inputmode="inputmode"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :type="computedType"
      :class="inputElClass"
    />
    <FormControlIcon v-if="icon" :icon="icon" :h="controlIconH" />
  </div>
</template>
