import { defineStore } from 'pinia'

import type { Event, User } from '@formyevent/model'

export const useMainStore = defineStore('main', () => {
  const user = ref<User | undefined>(undefined)
  const userName = ref<string | undefined>(undefined)
  const userEmail = ref<string | undefined>(undefined)
  const userAvatar = ref<string | null>(null)
  const isFieldFocusRegistered = ref(false)
  const events = ref<Event[]>([])

  function setUser(payload: User) {
    user.value = payload
    if (!payload) {
      return
    }
    // userAvatar.value = payload.avatar || null
    userEmail.value = payload.email
    userName.value = payload?.name || payload.email?.replace(/@.+$/, '')
  }

  return {
    user,
    userName,
    userEmail,
    userAvatar,
    isFieldFocusRegistered,
    events,
    setUser,
  }
})
